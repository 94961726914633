<template>
	<div class="sticky top-0 z-40 bg-white py-4">
		<div class="constraints">
			<div class="flex flex-row items-center justify-between">
				<div class="flex flex-row items-center space-x-8 font-medium">
					<nuxt-link
						class="mr-8"
						to="/">
						<logo class="w-40" />
					</nuxt-link>
					<nav-left-elements :hoverable="true" />
				</div>
				<div class="flex flex-row items-center space-x-6">
					<nuxt-link
						class="text-lg font-bold"
						to="/login"
						>Login
					</nuxt-link>
					<nuxt-link
						class="w-full rounded-full bg-primary px-6 py-2 text-lg font-bold text-white transition-opacity hover:opacity-75"
						to="/register"
						>Register
					</nuxt-link>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup></script>
