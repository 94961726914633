<template>
	<div class="sticky top-0 z-40">
		<div class="constraints relative border-b border-gray-100 bg-white py-4">
			<div class="flex flex-row items-center justify-between md:hidden">
				<nuxt-link to="/">
					<logo class="w-40" />
				</nuxt-link>
				<button @click="miniNavOpen = !miniNavOpen">
					<Bars3Icon class="h-8 w-8 text-black" />
				</button>
			</div>
		</div>
		<div
			v-if="miniNavOpen"
			class="absolute z-20 w-full border-b border-gray-100 bg-white px-32 py-6 shadow-sm">
			<div class="flex flex-col items-center space-y-4 font-medium">
				<nav-left-elements :hoverable="false" />
				<nuxt-link
					class="text-lg font-bold"
					to="/login"
					@click="miniNavOpen = false"
					>Login
				</nuxt-link>
				<nuxt-link
					class="rounded-full bg-primary px-6 py-2 text-lg font-bold text-white transition-opacity hover:opacity-75"
					to="/register"
					@click="miniNavOpen = false"
					>Register
				</nuxt-link>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
import { Bars3Icon } from "@heroicons/vue/24/solid";

const miniNavOpen = ref(false);
</script>
